<template>
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3 text-center">
        <h1 class="h3 -upper">{{ $t('Zenith Watches') }}</h1>
        <img src="../assets/img/404.png" alt aria-hidden="true" />
        <div class="h4">{{ $t('Sorry, that page doesn’t exist!') }}</div>
        <div class="wysiwyg">{{ $t('The page you are looking for might have been removed') }}</div>
        <a :href="absoluteUrl" class="btn">{{ $t('Return to home') }}</a>
        <!-- <router-link :to="localizedUrl + '/'" class="btn">{{ $t('Return to home') }}</router-link> -->
      </div>
    </div>
  </div>
</template>

<script>
import { ReadyHook } from 'vue-asyncready'
import { metaLinkBuilder } from '../utilities'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import config from 'config'
export default {
  name: 'PageNotFound',
  mixins: [ReadyHook],
  metaInfo() {
    return metaLinkBuilder(this.$route)
  },
  asyncData({ context }) {
    return new Promise((resolve, reject) => {
      if (context) {
        context.output.cacheTags.add(`page-not-found`)
        context.server.response.statusCode = 404
      }
      return resolve()
    })
  },
  computed: {
    absoluteUrl() {
      return config.client.url
      // const storeView = currentStoreView()
      // return storeView && storeView.url ? storeView.url : ''

    },
  },
}
</script>

<style lang="scss" scoped>
@import '~theme/css/core';

.container {
  padding-top: 8rem;
  padding-bottom: 10rem;
  color: var(--zenith-component-text);
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-items: center;

  .row {
    width: 100%;
  }

  img {
    @media (prefers-color-scheme: dark) {
      filter: var(--zenith-img-color-invert);
    }
  }

  #search {
    margin-bottom: 2rem;
  }
  .h4 {
    margin-bottom: 3rem;
    color: var(--zenith-black);
  }
  .wysiwyg {
    margin-bottom: 3rem;
  }
  .btn {
    margin: 0 1rem;
    width: calc(50% - 3rem);

    @include bp(max md) {
      width: 100%;
      margin: 1rem 0;
    }
  }
}
</style>
